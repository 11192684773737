<template>
  <div>
    <table class='mt-4 text-sm'>
      <thead>
        <tr class='bg-gray-200 text-left uppercase tracking-wide bposition-b'>
          <th class='px-2 py-2 text-center border-r border-l border-b border-t' colspan='6'>기본정보</th>
          <th class='px-2 py-2 text-center border-r border-b border-t' colspan='3'>투자원금(투자자산)</th>
          <th class='px-2 py-2 text-center border-r border-b border-t' colspan='3'>현재원금(투자자산)</th>
          <th class='px-2 py-2 text-center border-r border-b border-t' colspan='3'>현재원금(기초자산)</th>
          <th class='px-2 py-2 text-center border-r border-b border-t' colspan='5'>회수금</th>
          <th v-if='!is_direct' class='bg-blue-100 px-2 py-2 text-center border-r border-b border-t' colspan='3'>약정금(간접투자)</th>
        </tr>
      </thead>
      <tbody>
        <tr class='bg-gray-200 text-left uppercase tracking-wide bposition-b'>
          <th class='px-2 py-2 text-left border-r border-l border-b'>기준일</th>
          <th class='px-2 py-2 text-left border-r border-b'>투자번호</th>
          <th class='px-2 py-2 text-left border-r border-b'>투자일</th>
          <th class='px-2 py-2 text-left border-r border-b'>펀드명</th>
          <th class='px-2 py-2 text-left border-r border-b'>종목코드</th>
          <th class='px-2 py-2 text-left border-r border-b'>종목명</th>
          <th class='px-2 py-2 text-center border-r border-b'>투자수량</th>
          <th class='px-2 py-2 text-center border-r border-b'>투자단가</th>
          <th class='px-2 py-2 text-center border-r border-b'>투자금액</th>
          <th class='px-2 py-2 text-left border-r text-gray-900'>현재수량</th>
          <th class='px-2 py-2 text-left border-r text-gray-900'>현재단가</th>
          <th class='px-2 py-2 text-left border-r text-gray-900'>현재금액</th>
          <th class='px-2 py-2 text-left border-r border-b'>기초수량</th>
          <th class='px-2 py-2 text-left border-r border-b'>기초단가</th>
          <th class='px-2 py-2 text-left border-r border-b'>기초금액</th>
          <th class='px-2 py-2 text-left border-r border-b'>회수금</th>
          <th class='px-2 py-2 text-left border-r border-b'>회수금원금</th>
          <th class='px-2 py-2 text-left border-r border-b'>손익</th>
          <th class='px-2 py-2 text-left border-r border-b'>수익률</th>
          <th class='px-2 py-2 text-left border-r border-b'>회수율</th>
          <th v-if='!is_direct' class=' px-2 py-2 text-left border-r border-b bg-blue-100'>수량</th>
          <th v-if='!is_direct' class=' px-2 py-2 text-left border-r border-b bg-blue-100'>단가</th>
          <th v-if='!is_direct' class=' px-2 py-2 text-left border-r border-b bg-blue-100'>금액</th>
        </tr>
        <tr v-for='position in positions'
          @click='selectPosition(position)'
          :class='highlightRow(position.id)'
          :key='`position-${position.id}`'>
          <td class='px-2 py-2 text-left border-r border-l'>{{ position.created_on }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ position.investment_id }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ position.traded_on }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ position.short_name }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ position.share_code }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ position.name}}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.investment_quantity) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.investment_price) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.investment_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.quantity) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.average_price) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.acquired_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.base_quantity) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.base_price) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.base_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.collection_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.original_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(realizedGain(position)) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.collection_gain_rate) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(position.collection_rate) }}</td>
          <td v-if='!is_direct' class='px-2 py-2 text-right border-r'>{{ toNumber(position.contract_quantity) }}</td>
          <td v-if='!is_direct' class='px-2 py-2 text-right border-r'>{{ toNumber(position.contract_price) }}</td>
          <td v-if='!is_direct' class='px-2 py-2 text-right border-r'>{{ toNumber(position.contract_value) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import numbro from 'numbro'

export default {
  name: 'Positions',
  props: [
    'positions',
    'is_direct'
  ],
  data () {
    return {
      selectedPosition: '',
    }
  },
  methods: {
    realizedGain (position) {
      return (position.collection_value - position.original_value)
    },
    highlightRow (id) {
      return id === this.selectedPosition.id ? 'bg-blue-100 font-semibold border-b' : 'border-b'
    },
    selectPosition (position) {
      if (position) {
        this.selectedPosition = position
      }
    },
    toNumber (number) {
      if (number) {
        return numbro(number).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
  },
}
</script>
